<template>
  <v-footer dark inset relative color="#3E4095" class="px-0 mt-2">
    <div class="line-footer"></div>
    <v-col class="text-center pt-5" cols="12">
      <span class="mr-5">
        © {{ new Date().getFullYear() }} | Todos os direitos reservado HUWE |
        Mapplet | Legado
      </span>

      <v-btn
        href="https://www.instagram.com/funerariahuwe/"
        target="_blank"
        icon
      >
        <v-icon color="#fff" v-text="'mdi-instagram'" />
      </v-btn>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),
}
</script>
